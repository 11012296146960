// variable

$dark-red-color: #50cd89;
$light-red-color: #47be7d;
$primary: #009ef7;
$btn-bg-red-color: #50cd89;
$success: #1bc5bd !default;
$success-active: #0bb7af !default;
$danger: #f1416c;
$gray-100: #f5f8fa !default;
$gray-200: #eff2f5 !default;
$gray-300: #e4e6ef !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;

$gray-100-dark: #1b1b29 !default;
$gray-200-dark: #2b2b40 !default;
$gray-300-dark: #323248 !default;
$gray-400-dark: #474761 !default;
$gray-500-dark: #565674 !default;
$gray-600-dark: #6d6d80 !default;
$gray-700-dark: #92929f !default;
$gray-800-dark: #cdcdde !default;
$gray-900-dark: #ffffff !default;

$info: #8950fc !default;
$info-active: #7337ee !default;

Button[type='submit'] {
  background-color: #138fca !important;
  font-size: 16px;
  padding: 10px 25px !important;
  color: #fff !important;

  &:hover {
    background-color: #1e6b90 !important;
  }
}

.sbmt-btn {
  background-color: #138fca !important;
  padding: 10px 25px !important;
  color: #fff !important;
  font-size: 16px;

  &:hover {
    background-color: #1e6b90 !important;
  }
}

.searchWrapper {
  border: 0 !important;
}

#kt_login_signin_form {
  h1 {
    font-size: 30px;
  }

  h1,
  a {
    color: $dark-red-color;
  }
}

.aut-close-icon {
  background-color: $danger !important;
  color: #fff !important;
  border-radius: 100% !important;
  height: 35px !important;
  width: 35px !important;
  padding: 6px !important;

  svg {
    color: #fff !important;
  }
}

#kt_modal_add_Customer,
#kt_modal_add_message,
#kt_modal_add_user,
#kt_modal_add_ticketmanagement,
#kt_modal_add_distributor,
#kt_modal_add_customermanagement,
#kt_modal_add_claimmanagement,
#kt_modal_add_sendReceiveSparePart,
#kt_modal_add_productreturnmanagement {
  .modal-header {
    background-color: #f5f5f5;

    .btn-icon {
      svg {
        color: #292929 !important;
      }
      svg:hover {
        color: #16acec !important;
      }
    }
  }

  .modal-body h2 {
    background-color: #f1f1f1;
    font-size: 18px !important;
    color: #505050 !important;
    padding: 15px 20px;
    margin-bottom: 15px;
  }

  #kt_modal_add_servicecenter_scroll > div,
  #kt_modal_add_customersupport_scroll > div,
  #kt_modal_add_sendReceiveSparePart_scroll > div {
    padding: 8px 30px;
  }

  div label {
    font-size: 14px !important;
    text-transform: uppercase;
    color: #6a6a6a;
    font-weight: 500 !important;
    padding-left: 0px;
  }

  div input::-webkit-input-placeholder {
    opacity: 0.5 !important;
  }
}

.add-service-form-tab {
  ul {
    background-color: #f1f1f1;

    li {
      font-size: 16px !important;
      font-weight: 500;
      color: #000;

      Button {
        padding: 12px 35px;
      }
    }
  }

  .nav-tabs .nav-link.active {
    background-color: transparent;
    color: $dark-red-color;
    border: 0;
    border-bottom: 3px solid $dark-red-color;
  }
}

.modal-dialog {
  .modal-body form label {
    font-size: 14px !important;
    text-transform: uppercase;
    color: #4a4a4a;
  }

  #kt_modal_add_distributor,
  #kt_modal_add_customersupport,
  #kt_modal_add_supportagent,
  #kt_modal_add_categorymanagement,
  #kt_modal_add_modelmanagement,
  #kt_modal_add_productmanagement,
  #kt_modal_add_partmanagement,
  #kt_modal_add_customermanagement {
    .modal-header h2 {
      color: $dark-red-color !important;
    }
  }

  // added by Dharmendra on 04/04/2023
  .custom_select {
    .css-13cymwt-control {
      background-color: inherit;
      border: none;
    }

    .css-t3ipsp-control {
      border: none;
      box-sizing: none;
      background-color: none;
    }

    .Select__indicator-separator,
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }

  // end css

  div input::-webkit-input-placeholder {
    opacity: 0.5;
  }
}

.add-service-form-tab {
  ul {
    background-color: #f1f1f1;

    li {
      font-size: 16px !important;
      font-weight: 500;
      color: #000;

      Button {
        padding: 12px 35px;
      }
    }
  }

  .nav-tabs .nav-link.active {
    background-color: transparent;
    color: $dark-red-color;
    border: 0;
    border-bottom: 3px solid $dark-red-color;
  }
}

.table.gy-5 td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.menu-item .menu-link {
  padding: 5px !important;
}

.menu-item .menu-link .view-icon {
  height: 18px;
}

// end css
.rmsc .dropdown-container {
  background-color: #f5f8fa !important;
  border: 0 !important;
  height: 43px !important;
  padding-top: 5px !important;
}

.multiSelectContainer {
  background-color: var(--kt-input-solid-bg-focus) !important;
  border-color: var(--kt-input-solid-bg-focus) !important;
  min-height: 42px !important;
  border-radius: 6px !important;
  padding: 3px 5px !important;
}

.Select__control {
  border: none;
  background: #f5f8fa !important;
  box-shadow: none !important;
  padding: 4px 0;
}

.css-1jqq78o-placeholder {
  font-size: 14px !important;
  color: hsl(0deg 0% 40%) !important;
}

.css-13cymwt-control {
  border: none !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border-color: none !important;
}

.css-t3ipsp-control:hover {
  border: none !important;
}

input::placeholder {
  font-size: 14px !important;
  color: hsl(0deg 0% 40%) !important;
}

.form-check-custom.form-check-sm .form-check-input {
  height: 1.75rem !important;
  width: 1.75rem !important;
}

.app-main {
  min-height: calc(100vh - 70px);
}

// .table tr:first-child, .table th:first-child, .table td:first-child {
//   padding-left: 15px;
// }
.table tr:last-child,
.table th:last-child,
.table td:last-child {
  padding-right: 15px !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: inherit !important;
}

.table-striped > tbody > tr:hover,
.table-striped > tbody > tr:nth-of-type(odd) > *:hover {
  background-color: #eef3f5 !important;
}

//. Added css by Dharmendra on 10/04/2023
.dlt-mdl-icon i {
  font-size: 60px !important;
}

.deletemessage {
  background-color: #e8fff3;
  font-size: 16px;
  padding: 12px 15px;
}

// end css by Dharmendra on 10/04/2023

.detailmodel {
  .modal-content {
    background-color: #f5f8fa !important;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

.show.menu-dropdown > .menu-sub-dropdown,
.menu-sub-dropdown.menu.show,
.menu-sub-dropdown.show[data-popper-placement] {
  max-height: 650px;
  min-height: 300px;
  //  overflow-y: auto;
}

.apexcharts-menu-icon {
  display: none;
}

.form-select-sm {
  background-color: #f5f8fa !important;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:active .css-13cymwt-control:focus,
.css-13cymwt-control:active {
  background-color: #ffffff !important;
  outline: 0 !important;
  padding: 5px;
}

.view-ticket-tab ul.nav-tabs {
  border-color: transparent !important;
}

.view-ticket-tab .cls-btn {
  position: absolute;
  right: 20px;
  top: 15px;
}

.view-ticket-tab ul li {
  Button {
    color: #333333 !important;
    font-size: 18px;
    padding: 15px 18px;
    font-weight: 600;
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 3px solid transparent;
  }

  .active {
    border-bottom: 3px solid #009ef7 !important;
    color: #009ef7 !important;
  }
}

.view-ticket-tab form {
  background-color: #fff !important;
  padding: 25px;
}

.view-ticket-tab form h2 {
  background-color: #f1f1f1;
  font-size: 18px !important;
  color: #505050 !important;
  padding: 15px 20px;
  margin-bottom: 15px;
}

.graph-left-tkt-lbl {
  position: absolute;
  transform: rotate(270deg);
  left: -86px;
  top: 50%;
  font-size: 13px;
  text-align: center;
}

.fxd-higt-tbl {
  .card {
    .card-body {
      max-height: 350px;
      overflow-y: auto;

      table > tbody > tr th a,
      table > tbody > tr td > div,
      table > tbody > tr td a {
        font-weight: normal !important;
        color: inherit !important;
        // padding-left:15px;
      }

      table > thead > tr th {
        font-weight: 600 !important;
        color: #000 !important;
      }
    }
  }
}

.table.sentreceiveparts-report > tbody > tr td,
.table.supportticketarchives-report > tbody > tr td,
.table.claimarchives-report > tbody > tr td,
.table.pendingtoreceiveparts-report > tbody > tr td,
.table.supportticketsanalysis-report > tbody > tr td {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.cust-pagination .MuiInputBase-input {
  margin-top: -10px;
}

.cust-pagination svg {
  margin-top: -7px;
}

.upld-img > div {
  height: 100px;
  width: 150px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  border: 1px solid #f5f5f5;
  position: relative;
}

.upld-img > div img {
  max-width: 100%;
}

.upld-img > div span {
  background-color: #fff;
  color: #000;
  font-size: 21px;
  width: 25px;
  height: 25px;
  line-height: 23px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

.form-switch .form-check-input {
  width: 2.2rem !important;
  margin: 0rem !important;
}

.form-check-input {
  height: 1.2rem !important;
}

.form-switch {
  margin-left: 0rem !important;
  padding: 0.1rem !important;
}

.form-check {
  margin-bottom: 0rem !important;
}

.auth-bg .form input,
textarea {
  background-color: #f5f5f5 !important;
  border: 0 !important;

  &:hover,
  &:focus {
    background-color: #f2f2f2 !important;
  }
}

form {
  label {
    font-weight: 600 !important;
  }
}

table {
  thead tr {
    background-color: #f2f2f2 !important;
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    padding-left: 15px !important;
  }

  thead tr th:last-child,
  tbody tr td:last-child {
    padding-right: 15px !important;
  }
}

.checkbox-input input {
  height: 25px !important;
}

.form-control,
.multiselect-container {
  border: 1px solid $gray-800-dark !important;
  background-color: #fff !important;
}

.multiselect-container {
  height: 50px !important;
}

.custom_select {
  background-color: #ffffff !important;
  border-radius: 8px !important;
  border: 1px solid $gray-800-dark !important;
}

.btn-cancel {
  // border-radius: 100% !important
  svg {
    color: #444141 !important;
  }
  svg:hover {
    color: #007bff !important;
  }
}

.report-radio-btn input[type='radio'] {
  height: 23px !important;
  border: 1px solid #aaa;
}

.date-picker-border {
  height: 43px !important;
}

.custom_select .css-3a12i2-control,
.custom_select .css-13cymwt-control {
  border-radius: 8px !important;
  border: 0 !important;
  height: 43px !important;
  background-color: #ffffff !important;
}

.report-radio-btn .form-check-input:checked[type='radio'] {
  background-color: #009abe !important;
  border: 1px solid #009abe !important;
}

.css-pdct74-MuiTablePagination-selectLabel,
.css-16c50h-MuiInputBase-root-MuiTablePagination-select,
.css-levciy-MuiTablePagination-displayedRows,
.css-wjh20t-MuiPagination-ul li button {
  font-size: 16px !important;
}

.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item .menu-link {
  padding: 8px 14px !important;
}

.app-sidebar-menu .menu > .menu-item {
  margin-bottom: 5px !important;
}

.app-navbar .app-navbar-item i {
  font-size: 18px;
  // color: $primary !important;
}

.app-navbar .notif .btn-custom {
  padding: 5px 15px;
  background-color: #ebf8ff !important;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item .menu-link:hover {
  background-color: #e2b801 !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #138fca !important;
  color: #fff;
  line-height: 5px !important;
}
.report-ceckbox {
  height: 20px !important;
}
.table tr:first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  th:first-child {
    border-top-left-radius: 12px !important;
  }
  th:last-child {
    border-top-right-radius: 12px !important;
  }
}
.long-model .modal-body {
  max-height: calc(100vh - 125px);
}

.notifications-list {
  .note-unread {
    background-color: rgba(140, 25, 55, 0.03);

    > div {
      font-weight: 600;
      color: #000;
      margin-bottom: 0;
      border-bottom: #ccc thin dashed;
      font-size: 15px;
    }
  }
  .read {
    background-color: #f5f5f5;
    > div {
      font-weight: normal;
      color: #333;
      margin-bottom: 0;
      font-size: 15px;
      border-bottom: #ccc thin dashed;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
  padding-right: 15px;
}
.css-1nsudsg-control {
  border: #009abe !important;
}

.csv-pdf-css {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 17px;
  padding-bottom: 17px;
  width: 105px;
}

.notification-position-change {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transform: translate(-967px, 70px) !important;
}
.remarks-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.remarks-cell:hover {
  white-space: normal;
}
.open-close-indicater {
  top: 50px !important;
}

// .party_select {
//   flex: 1;
// }
.notification-change-position {
  transform: translate(372.75px, -47.5px) !important;
  background-color: rgba(255, 255, 255, 0.95) !important;
}
.red-dot {
  right: inherit !important;
  margin-left: 3px !important;
}
.custom-noti-link {
  max-width: 180px !important;
  min-width: 180px !important;
}
.custom-party-ledger-tbl.gy-5 td {
  padding: 0px !important;
}

.table.custom-party-ledger-tbl.gy-5 td {
  tbody > tr > td {
    height: 32px !important;
    padding: 0px !important;
  }
}
.menu-item:hover {
  cursor: pointer;
}
.logo-color {
  color: #ffffff !important;
}
.remarks-cell:hover {
  white-space: nowrap !important;
}
.css-rbcm31-control,
.css-pmaajo-control {
  background-color: transparent !important;
}
.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  padding: 2px !important;
}
.app-sidebar-logo {
  transition: all 300ms;
}
[data-kt-app-sidebar-minimize='on'] .app-sidebar .app-sidebar-logo {
  padding-left: 15px !important;
}
.table.gy-5 th,
.table.gy-5 td {
  vertical-align: middle !important;
}

body table.dataTable > thead > tr > td:not(.sorting_disabled),
body table.dataTable > thead > tr > th:not(.sorting_disabled) {
  padding-right: 0.75rem;
}

.table-sort,
.table-sort-asc,
.table-sort-desc {
  position: relative;
}

.table-sort:after,
.table-sort-asc:after,
.table-sort-desc:after {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0 !important;
}

/* Custom CSS file, e.g., styles.css */
.custom-tooltip .tooltip-inner {
  color: rgb(16, 13, 13) !important;
  background-color: rgb(195, 195, 180) !important; /* You can customize other properties as needed */
}
.custom-tooltip .tooltip-arrow::before {
  border-top-color: rgb(195, 195, 180) !important; /* Customize the arrow color */
  border-bottom-color: rgb(195, 195, 180) !important; /* Customize for other arrow positions if needed */
}

.custom-hover-effect:hover {
  background-color: #a7cada; /* Desired hover background color */
  color: rgb(26, 22, 22); /* Desired hover text color */
}

.custom-sticky-top {
  position: sticky;
    top: 0;
    z-index: 9;
}

.header-custom-sticky-top {
  position: sticky;
    top: 0;
    z-index: 9;
}

.custom-add-message-sticky {
  position: sticky !important;
  top: 89px !important;
}

.custom-table-scroll {  height:calc(100vh - 225px)
}
.app-content {
  padding-bottom: 5px !important;
}

.tooltip{
  position: absolute;
  opacity: 0;
  z-index: -1!important;
}

.tooltip.show{z-index: var(--bs-tooltip-zindex)!important;}

.text-uppercase::placeholder {
  text-transform: capitalize; 
}
.text-uppercase {
  text-transform: uppercase; 
}
.file-input-dashed-border {
  border: 2px dashed #ccc;
  border-radius: 25px;
  padding: 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.file-input-placeholder {
  pointer-events: none;
}

.download-link {
  position: absolute;
  
  right: 50px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.editable-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 10px;
  padding: 4px;
}


.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  padding: 20px;
  border-radius: 12px;
  background: #f9f9f9;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  margin-bottom: 25px;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.status-text {
  font-size: 1rem;
  font-weight: 600;
  color: #4a4a4a;
  text-align: center;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

/* Adding some subtle animations */
.spinner-wrapper > .status-text {
  animation: fadeIn 0.7s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .status-container {
    padding: 15px;
    border-radius: 8px;
  }

  .spinner-wrapper {
    gap: 15px;
  }

  .status-text {
    font-size: 0.9rem;
  }
}

.custom-modal-width .modal-dialog {
  max-width: 700px !important;
}
