//
// App Navbar
//

.app-navbar {
    display: flex;
    align-items: stretch;

    .app-navbar-item {
        display: flex;
        align-items: center;
    }

    &.app-navbar-stretch {
        .app-navbar-item {
            align-items: stretch;
        }
    }
}


@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .btn-container {
    position: relative;
    display: inline-block; /* Added to make sure container takes only necessary space */
  }
  
  .red-dot {
    position: absolute;
    top: -5px ;
    right: -7px;
    transform: translateY(-50%);
    width: 8px;
    height: 7px;
    background-color: red;
    border-radius: 100%;
    animation: blink 1s infinite;
    z-index: 1;  }
